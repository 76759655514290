<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.order_type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-input v-model="listQuery.order_no" placeholder="请输入订单号" class="filter-item" style="width: 250px;" clearable />

      <el-cascader ref="tree" :options="schoolWithShops" placeholder="所属食堂店铺" :props="props" :show-all-levels="false" collapse-tags @change="handleChangeShops" class="filter-item" style="width: 250px;" clearable></el-cascader>

      <el-select v-model="listQuery.pay_status" placeholder="支付状态" class="filter-item" style="width: 140px;" clearable>
        <el-option label="待支付" :value="0" />
        <el-option label="已支付" :value="1" />
        <el-option label="已退款" :value="2" />
        <el-option label="已取消" :value="3" />
      </el-select>

      <el-select v-model="listQuery.pay_type" placeholder="支付类型" class="filter-item" style="width: 140px;" clearable>
        <el-option label="微信" :value="1" />
        <el-option label="支付宝" :value="2" />
      </el-select>

      <el-select v-model="listQuery.split_order_status" placeholder="分账订单状态" class="filter-item" style="width: 140px;" clearable>
        <el-option label="无需分账" :value="0" />
        <el-option label="待分账" :value="1" />
        <el-option label="分账中" :value="2" />
        <el-option label="分账完成" :value="3" />
        <el-option label="分账失败" :value="4" />
      </el-select>

      <el-select v-model="listQuery.is_finish_split" placeholder="是否完结分账" class="filter-item" style="width: 140px;" clearable>
        <el-option label="未完结分账" :value="0" />
        <el-option label="已完结分账" :value="1" />
      </el-select>

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="收银台消费记录.xls" worksheet="收银台消费记录">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading" style="margin-bottom: 20px;">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.payMoney || 0 }}</div>
            <div class="subtitle">累计消费金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.payCount || 0 }}</div>
            <div class="subtitle">累计消费次数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.refundMoney || 0 }}</div>
            <div class="subtitle">累计退款金额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ this.refundCount || 0 }}</div>
            <div class="subtitle">累计退款次数</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div style="margin-bottom: 20px;">
      <span style="font-size:18px;margin-left:30px;">分账总额：{{ledgerMoney}}</span>
      <span style="font-size:18px;margin-left:30px;" v-for="(item,index) in ledgerRole" :key="index">{{item.role_name}}：{{item.ledger_role_money}}</span>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>

      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.split_order_no }}
        </template>
      </el-table-column>

      <el-table-column label="店铺名称" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>

      <el-table-column label="设备编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_id }}
        </template>
      </el-table-column>

      <el-table-column label="支付状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.pay_status === 0">待支付</el-tag>
          <el-tag type="success" v-else-if="scope.row.pay_status === 1">已支付</el-tag>
          <el-tag type="warning" v-else-if="scope.row.pay_status === 2">已退款</el-tag>
          <el-tag type="danger" v-else-if="scope.row.pay_status === 3">已取消</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="支付类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
          <el-tag type="primary" v-else-if="scope.row.pay_type === 2">支付宝</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="支付金额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>

      <el-table-column label="分账订单状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.split_order_status === 0">无需分账</el-tag>
          <el-tag type="info" v-else-if="scope.row.split_order_status === 1">待分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.split_order_status === 2">分账中</el-tag>
          <el-tag type="success" v-else-if="scope.row.split_order_status === 3">分账完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.split_order_status === 4">分账失败</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="分账错误原因" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.split_order_status === 4 ? scope.row.split_error_msg : "" }}
        </template>
      </el-table-column>

      <el-table-column label="下单时间" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handleCashierOrderSplitLog(scope.row)">分账收入记录</el-button>

          <el-button type="warning" size="mini" @click="handleCashierOrderFinishSplit(scope.row)" :disabled="(scope.row.split_order_status !== 0 && scope.row.split_order_status !== 4) || scope.row.is_finish_split === 1">完结分账</el-button>

          <el-button type="danger" size="mini" @click="handleCashierOrderCancel(scope.row)" :disabled="!(scope.row.pay_status === 1 && scope.row.pay_time >= currentDate)">取消订单</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";
var moment = require("moment");

export default {
  components: {
    downloadExcel
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_ids: [],
        order_no: "",
        pay_status: "",
        pay_type: "",
        split_order_status: "",
        order_type: 1,
        is_finish_split: "",
        start_date: "",
        end_date: "",
      },
      schoolWithShops: [],
      props: { multiple: true },
      dateArr: null,
      ledgerMoney: null,
      ledgerRole: null,
      payMoney: null,
      payCount: null,
      refundMoney: null,
      refundCount: null,
      currentDate: null,
      json_fields: {
        订单号: {
          field: "order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        分账单号: {
          field: "split_order_no",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        店铺名称: "shop.shop_name",
        设备编号: {
          field: "device_id",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
        支付状态: {
          field: "pay_status",
          callback: (value) => {
            if (value === 0) return "待支付";
            else if (value === 1) return "已支付";
            else if (value === 2) return "已退款";
            else if (value === 3) return "已取消";
            else return "";
          }
        },
        支付类型: {
          field: "pay_type",
          callback: (value) => {
            if (value === 1) return "微信";
            else if (value === 2) return "支付宝";
            else return "";
          }
        },
        支付金额: "pay_money",
        分账订单状态: {
          field: "split_order_status",
          callback: (value) => {
            if (value === 0) return "无需分账";
            else if (value === 1) return "待分账";
            else if (value === 2) return "分账中";
            else if (value === 3) return "分账完成";
            else if (value === 4) return "分账失败";
            else return "";
          }
        },
        分账错误原因: "split_error_msg",
        下单时间: {
          field: "created_at",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          }
        },
      },
      exportLoading: false,
    };
  },
  created() {
    this.listQuery.start_date = moment().format("YYYY-MM-DD");
    this.listQuery.end_date = moment().format("YYYY-MM-DD");
    this.dateArr = [this.listQuery.start_date, this.listQuery.end_date];
    this.currentDate = moment().format("YYYY-MM-DD 00:00:00");
    this.getList();
    this.getSchoolWithShop();
  },
  computed: {
    ...mapGetters(["schools", "school_id", "user"])
  },
  watch: {
    "listQuery.order_type": {
      handler(newValue, oldValue) {
        this.listQuery.shop_ids = [];
        // 清空选中的节点
        this.$refs.tree.$refs.panel.clearCheckedNodes();
        // 设置为空可以让节点不高亮显示
        this.$refs.tree.$refs.panel.activePath = [];
        this.getSchoolWithShop();
      },
      deep: true
    },
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getSchoolWithShop();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_date = newVal[0];
        this.listQuery.end_date = newVal[1];
      } else {
        this.listQuery.start_date = "";
        this.listQuery.end_date = "";
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/cashierOrder/list",
        method: "get",
        params: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.list.data;
        this.total = response.data.list.total;
        this.ledgerMoney = response.data.ledgerMoney;
        this.ledgerRole = response.data.ledgerRole;
        this.payMoney = response.data.payMoney;
        this.payCount = response.data.payCount;
        this.refundMoney = response.data.refundMoney;
        this.refundCount = response.data.refundCount;
        this.listLoading = false;
      });
    },
    getSchoolWithShop() {
      if (!this.school_id) {
        return;
      }
      request({
        url: "/api/backend/school/schoolWithShop",
        method: "get",
        params: {
          school_id: this.school_id,
          type: this.listQuery.order_type
        }
      }).then(response => {
        let data = response.data;
        let schoolWithShops = [];

        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.canteens.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.canteen_name,
                children: []
              };
              element2.shops.forEach(element3 => {
                let obj3 = {
                  value: element3.id,
                  label: element3.shop_name,
                };
                obj2.children.push(obj3);
              });
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.shop_list.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.shop_name,
              };
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        }

        this.schoolWithShops = schoolWithShops;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCashierOrderSplitLog(item) {
      this.$router.push(`/cashier/orderSplitLog?order_no=${item.order_no}`);
    },
    handleCashierOrderFinishSplit(item){
      request({
        url: "/api/backend/cashierOrder/finishSplit",
        method: "post",
        data: {
          order_no: item.order_no
        }
      }).then(() => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        this.getList();
      });
    },
    handleChangeShops(data) {
      let shop_ids = [];
      if (data.length > 0) {
        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            shop_ids.push(element[2]);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            shop_ids.push(element[1]);
          });
        }
      }
      this.listQuery.shop_ids = shop_ids;
    },
    handleCashierOrderCancel(item){
      this.$confirm("确定要取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/cashierOrder/cancel",
          method: "post",
          data: {
            order_no: item.order_no
          }
        }).then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.getList();
        });
      });
    },
    handleExport() {
      if (!this.listQuery.start_date || !this.listQuery.end_date) {
        this.$message({
          type: "warning",
          message: "请选择时间段"
        });
        return;
      }

      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.page = 1;
      listQuery.limit = this.total;
      this.exportLoading = true;
      return request({
        url: "/api/backend/cashierOrder/list",
        method: "get",
        params: listQuery,
        timeout: 6000000,
      }).then(response => {
        if (response.data.list.data.length <= 0) {
          this.$message({
            type: "warning",
            message: "暂无记录"
          });
          return;
        }
        return response.data.list.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.exportLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
